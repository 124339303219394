import { defineStore } from "pinia";
export const useStatusStore = defineStore("lang", {
	state: () => ({
		statuses: {},
		statusesColors: {
			"Аллокирован": "#f60",
			"В обработке": "#f4a261",
			"Готов к отгрузке": "#ffd000",
			"Закрыт": "#152528",
			"Отгружен полностью": "#229631",
			"Отменен": "#b9b3af",
			"Отменен пользователем": "#b9b3af",
			"Отправлен на упаковку": "#64a4ce",
			"Оформлен": "#e75c00",
			"Упакован": "#0f558f",
			"Черновик": "#696663",
			"Ожидание": "#c5d0e6",
		},
	}),
	actions: {
		setStatuses(statuses) {
			this.statuses = statuses;
		},
	},
	getters: {
		getStatuses() {
			return this.statuses;
		},
		getStatusesColors() {
			return this.statusesColors;
		},
	},
});
